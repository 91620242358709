@if ($pageContent()) {
    <div>
        @if ($pageContent()?.Header) {
            <app-header [headerContent]="$pageContent()?.Header!" [menu]="$pageContent()?.Menu!" />
        }

        <div class="tw-container tw-mx-auto tw-flex tw-flex-col md:tw-flex-row tw-py-16 tw-px-8">
            <div class="tw-basis-1/3 sm:tw-max-w-[20rem]">
                <app-side-menu [menuItems]="menuItems"></app-side-menu>
            </div>
            <div class="tw-flex-grow">
                <router-outlet></router-outlet>
            </div>
        </div>

        @if ($pageContent()?.Footer) {
            <app-footer [footerContent]="$pageContent()?.Footer!" />
        }
    </div>
}
