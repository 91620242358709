import { Component, computed, Signal, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { map } from 'rxjs';
import { AppStore } from 'src/app/store/app.store';
import { LoginModalComponent } from '../login-modal/login-modal.component';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [DialogModule, LoginModalComponent],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
  providers: [DialogService],
  encapsulation: ViewEncapsulation.None,
})
export class LoginPageComponent {
  $isLoggedIn: Signal<boolean>;
  $queryParams: Signal<{ login?: boolean; redirectUrl?: string; email?: string; code?: string } | undefined>;
  $visible: Signal<boolean>;
  $email: Signal<string>;
  $code: Signal<string>;

  constructor(
    private dialogService: DialogService,
    private appStore: AppStore,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.$isLoggedIn = appStore.$isLoggedIn;
    this.$queryParams = toSignal(
      this.route.queryParams.pipe(
        map(({ loginModal, redirectUrl, user, code }) => ({ login: loginModal == '1', redirectUrl, email: user, code })),
      ),
    );

    this.$visible = computed(() => (!this.$isLoggedIn() && this.$queryParams()?.login) || false);
    this.$email = computed(() => this.$queryParams()?.email || '');
    this.$code = computed(() => this.$queryParams()?.code || '');
  }

  onVisibleChange(visible: boolean) {
    if (!visible) {
      this.router.navigate([], {
        queryParams: { loginModal: undefined, user: undefined, code: undefined, redirectUrl: undefined },
        queryParamsHandling: 'merge',
      });
    }
  }
}
