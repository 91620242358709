<ul class="tw-list-none tw-p-4 tw-m-0">
    @for (menuItem of menuItems; track menuItem.Slug; let i = $index) {
        <li>
            @if (i > 0) {
                <p-divider class="sidebar-divider" />
            }
            <a
                pRipple
                class="side-menu-item tw-p-3 tw-rounded-lg tw-text-700 tw-text-lg p-ripple"
                [routerLink]="menuItem.Slug"
                routerLinkActive="active"
                queryParamsHandling="merge"
            >
                {{ (menuItem.Text | translateSignal)() }}
            </a>
        </li>
    }
</ul>
