<div class="dtx-login-modal-form">
    <h1 class="dtx-login-modal-form__title">{{ $loginPageContent()?.Header }}</h1>
    <div class="dtx-login-modal-form__description tw-text-gray-500">
        {{ $loginPageContent()?.CheckYourEmailText }}:&ensp;<span style="color: var(--primary-500)">{{ email() }}</span>
    </div>
    <div class="tw-my-8">
        <div class="otp-container">
            @for (input of otpInputs; track i; let i = $index) {
                <input
                    pInputText
                    type="text"
                    [(ngModel)]="otpInputs[i]"
                    maxlength="1"
                    (keyup)="onKeyUp($event, i)"
                    (keydown)="onKeyDown($event, i)"
                    (paste)="onPaste($event)"
                    class="tw-w-10 tw-m-1 tw-text-center tw-text-lg tw-font-bold"
                    name="opt-{{ i }}"
                />
            }
        </div>
        @if (errorMessage) {
            <div class="tw-mt-1 .dtx-login-modal-form__error tw-text-red-500 tw-text-sm tw-text-center">{{ errorMessage }}</div>
        }
    </div>
    <div class="tw-flex tw-w-full tw-max-w-[18rem] tw-justify-between">
        <p-button
            class="dtx-login-modal-form__resend"
            [text]="true"
            [label]="resendLabel"
            severity="secondary"
            [disabled]="resendInSeconds > 0"
            (click)="resendCode()"
        />
        <p-button class="dtx-login-modal-form__submit" (click)="onSubmit()" [loading]="loading" [label]="'Verify' | translate" />
    </div>
</div>
