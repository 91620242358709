import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { AppStore } from 'src/app/store/app.store';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginPageService {
  constructor(
    private httpService: HttpService,
    private appStore: AppStore,
  ) {}

  async loginPageContent(siteName: string, language: string): Promise<LoginPageContent> {
    const params = {
      siteName,
      language,
    };
    return this.httpService.get<LoginPageContent>(`${environment.api}${environment.middleware}RetrieveLoginPage`, { params });
  }
}
