import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, Input, Output, Signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SkeletonModule } from 'primeng/skeleton';
import { StepsModule } from 'primeng/steps';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AppStore } from 'src/app/store/app.store';
import { LoginPageState } from '../../services/login-page.state';
import { EnterCodeComponent } from '../enter-code/enter-code.component';
import { EnterEmailComponent } from '../enter-email/enter-email.component';
@Component({
  selector: 'app-login-modal',
  standalone: true,
  imports: [ReactiveFormsModule, EnterEmailComponent, EnterCodeComponent, CommonModule, StepsModule, ProgressSpinnerModule, SkeletonModule, TranslatePipe],
  templateUrl: './login-modal.component.html',
  styleUrl: './login-modal.component.scss',
  providers: [],
})
export class LoginModalComponent {
  $loading: Signal<boolean>;
  $loginPageContent: Signal<LoginPageContent | null>;
  $step: Signal<'ENTER_EMAIL' | 'ENTER_OTP'>;
  $activeIndex: Signal<number>;

  @Input()
  redirectUrl? = '';

  @Input()
  email!: Signal<string>;

  @Input()
  code!: Signal<string>;

  @Output()
  succeed = new EventEmitter();

  items: MenuItem[] = [
    {
      label: this.translateService.translate('Email'),
    },
    {
      label: this.translateService.translate('OTP'),
    },
  ];

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private appStore: AppStore,
    private router: Router,
    private loginPageState: LoginPageState,
    private translateService: TranslateService,
  ) {
    this.$loginPageContent = this.loginPageState.$loginPageContent;
    this.$loading = computed(() => this.$loginPageContent() === null);

    this.$step = computed(() => (this.email() ? 'ENTER_OTP' : 'ENTER_EMAIL'));
    this.$activeIndex = computed(() => (this.$step() === 'ENTER_EMAIL' ? 0 : 1));
  }

  onEmailSucceed(email: string) {
    this.router.navigate([], { queryParams: { user: email }, queryParamsHandling: 'merge' });

  }
  onOTPSucceed(token: string) {
    this.appStore.token = token;
    this.onLoginSucceed();
  }

  onLoginSucceed() {
    // Redirect to the url given or close the dialog.
    if (this.redirectUrl) {
      this.router.navigateByUrl(this.redirectUrl);
    } else {
      this.succeed.emit();
    }

    this.messageService.add({
      severity: 'success',
      summary: this.translateService.translate('Login'),
      detail: this.$loginPageContent()?.SuccessText,
    });

  }
}
