<form [formGroup]="form" (ngSubmit)="onSubmit()" class="dtx-login-modal-form">
    <h1 class="dtx-login-modal-form__title">{{ $loginPageContent()?.Header }}</h1>
    <div class="dtx-login-modal-form__description tw-text-gray-500">{{ $loginPageContent()?.Body }}</div>
    <div class="tw-my-8 tw-w-full">
        <input
            pInputText
            name="email"
            formControlName="email"
            type="email"
            placeholder="{{ $loginPageContent()?.EmailPlaceholder }}"
            class="dtx-login-modal-form__input"
        />
        @if (errorMessage) {
            <div class="tw-mt-1 dtx-login-modal-form__error tw-text-red-500 tw-text-sm">{{ errorMessage }}</div>
        }
    </div>
    <p-button class="dtx-login-modal-form__submit" type="submit" [loading]="loading" [label]="'Login' | translate" />
</form>
