import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/http/http.service';
import { AppStore } from 'src/app/store/app.store';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountPageService {
  constructor(
    private httpService: HttpService,
    private appStore: AppStore,
  ) {}

  async loadAccountPage(siteName: string, language: string): Promise<AccountPage> {
    const params = {
      siteName,
      language,
    };
    return this.httpService.get<AccountPage>(`${environment.api}${environment.middleware}RetrieveAccountPage`, { params });
  }
}
