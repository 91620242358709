import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLinkActive, RouterModule } from '@angular/router';
import { DividerModule } from 'primeng/divider';
import { RippleModule } from 'primeng/ripple';
import { TranslateSignalPipe } from 'src/app/shared/pipes/translate-signal.pipe';
@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [CommonModule, DividerModule, RouterModule, RippleModule, RouterLinkActive, TranslateSignalPipe],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
})
export class SideMenuComponent {
  @Input()
  menuItems: MenuItem[] = [];
}
