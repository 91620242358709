import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AppStore } from 'src/app/store/app.store';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const appStore = inject(AppStore);
  const token = appStore.$token();
  const authReq = req.clone({
    headers: req.headers.set('x-dynatex-token', `${token}`),
  });

  return next(authReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        appStore.token = '';
      }
      return throwError(() => error);
    }),
  );
};
