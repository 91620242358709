import { afterNextRender, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { filter } from 'rxjs';
import { AppStore } from 'src/app/store/app.store';
import { TranslateSignalPipe } from '../../pipes/translate-signal.pipe';
import { TranslatePipe } from '../../pipes/translate.pipe';
import { PUSH_NOTIFICATION_CONFIRMATION, PushNotificationService } from '../../services/push-notification.service';
import { TranslateService } from '../../services/translate.service';

export const TOAST_KEY = 'push-notification-confirmation';

@Component({
  selector: 'app-push-notification-confirmation',
  standalone: true,
  imports: [ToastModule, ButtonModule, TranslatePipe, TranslateSignalPipe],
  templateUrl: './push-notification-confirmation.component.html',
  styleUrl: './push-notification-confirmation.component.scss',
})
export class PushNotificationConfirmationComponent {
  key = TOAST_KEY;
  loading = false;

  constructor(
    private messageService: MessageService,
    private pushNotificationService: PushNotificationService,
    private router: Router,
    private appStore: AppStore,
    private translateService: TranslateService,
  ) {
    afterNextRender(() => {
      const showConfirm = localStorage.getItem(PUSH_NOTIFICATION_CONFIRMATION) === null;
      if (showConfirm) {
        const subscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
          next: _ => {
            // If the user is logged in and not on the home page, show the push notification confirmation
            //  && this.appStore.$isLoggedIn()
            if (location.pathname !== '/' && this.appStore.$webManifest() !== null) {
              this.messageService.add({
                key: TOAST_KEY,
                sticky: true,
                closable: false,
              });
              subscription.unsubscribe();
            }
          },
        });
      }
    });
  }

  close() {
    this.messageService.clear(this.key);
  }

  async enablePushNotification() {
    try {
      this.loading = true;
      const status = await Notification.requestPermission();

      localStorage.setItem(PUSH_NOTIFICATION_CONFIRMATION, 'true');

      if (status === 'denied') {
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.translate('PushNotification', 'Push Notification'),
          detail: this.translateService.translate('PushNotificationDenied', 'Please enable push notifications in your browser settings.'),
        });
      } else {
        this.pushNotificationService.subscribe();
      }
    } catch (e) {
      console.error('Error subscribing to push notifications', e);
    }

    this.loading = false;
    this.close();
  }

  disablePushNotification() {
    localStorage.setItem(PUSH_NOTIFICATION_CONFIRMATION, 'false');
    this.pushNotificationService.unsubscribe();
    this.close();
  }
}
