import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppStore } from 'src/app/store/app.store';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = 'middleware/auth/${App}/SendLogon';

  constructor(
    private http: HttpClient,
    private appStore: AppStore,
  ) {}

  sendLogon(email: string): Observable<any> {
    // Assuming your API expects a POST request with an email in the body
    const params = { email: email, languageCode: this.appStore.$language().code };
    return this.http.get(`${environment.api}api/auth/${this.appStore.$appName()}/SendLogon`, { params });
  }

  sendCode(email: string, code: string): Observable<string> {
    const params = { email, code };
    return this.http.post(`${environment.api}api/auth/${this.appStore.$appName()}/token`, params, {
      responseType: 'text',
    });
  }
}
