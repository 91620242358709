import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, Signal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { LoginPageState } from '../../services/login-page.state';


@Component({
  selector: 'app-enter-code',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, ButtonModule, InputTextModule, FormsModule, TranslatePipe],
  templateUrl: './enter-code.component.html',
  styleUrl: './enter-code.component.scss',
})
export class EnterCodeComponent implements OnInit {
  OTP_INPUTS = 6;
  otpInputs = Array(this.OTP_INPUTS).fill('');
  errorMessage: string | undefined;

  $loginPageContent: Signal<LoginPageContent | null>;

  @Input()
  email!: Signal<string>;

  @Input()
  code!: Signal<string>;

  @Output()
  succeed = new EventEmitter<string>();

  loading = false;
  resendInSeconds = 0;
  RESEND_IN_SECONDS_TOTAL = 60;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private location: Location,
    private messageService: MessageService,
    private loginPageState: LoginPageState,
    private translateService: TranslateService,
  ) {
    this.startResendTimer();
    this.$loginPageContent = this.loginPageState.$loginPageContent;
  }

  get resendLabel(): string {
    return `${this.translateService.translate('Resend')}${this.resendInSeconds > 0 ? ` (${this.resendInSeconds}s)` : ''}`;
  }

  ngOnInit(): void {
    // ! we don't need to track the $code signal, so just call it once
    const code = this.code();
    if (code.length === this.OTP_INPUTS) {
      this.otpInputs = code.split('');
      this.onSubmit();
    }
  }

  startResendTimer() {
    this.resendInSeconds = this.RESEND_IN_SECONDS_TOTAL;
    const intervalId = setInterval(() => {
      this.resendInSeconds--;
      if (this.resendInSeconds <= 0) clearInterval(intervalId);
    }, 1000);
  }

  resendCode() {
    this.startResendTimer();
    this.authService.sendLogon(this.email()).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.translate('Success'),
          detail: `${this.$loginPageContent()?.CheckYourEmailText}, ${this.email()}`,
        });
      },
      error: error => {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.translate('Error'),
          detail: error.error?.message || this.$loginPageContent()?.ErrorText,
        });
      },
    });
  }

  onSubmit(): void {
    if (this.loading) return;
    if (this.otpInputs.some(x => x === '')) return;

    const code = this.otpInputs.join('');

    this.loading = true;
    this.errorMessage = '';
    // Call the API using authService
    this.authService.sendCode(this.email(), code).subscribe({
      next: token => {
        this.loading = false;
        this.succeed.emit(token);
      },
      error: error => {
        this.loading = false;
        this.errorMessage = error.error?.message || this.$loginPageContent()?.ErrorText;
      },
    });
  }

  onKeyUp(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;
    if (event.key !== 'Backspace' && input.value) {
      setTimeout(() => this.moveToNextInput(index));
    }
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    if (event.key === 'Backspace') {
      setTimeout(() => this.moveToPreviousInput(index));
    }
  }

  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData?.getData('text');
    if (clipboardData && clipboardData.length === this.OTP_INPUTS) {
      this.otpInputs = clipboardData.split('');
      this.onSubmit();
      event.preventDefault();
    }
  }

  moveToNextInput(index: number): void {
    if (index < this.OTP_INPUTS - 1) {
      const nextInput = document.querySelectorAll('input[type="text"]')[index + 1] as HTMLElement;
      nextInput.focus();
    } else {
      this.onSubmit();
    }
  }

  moveToPreviousInput(index: number): void {
    if (index > 0) {
      const prevInput = document.querySelectorAll('input[type="text"]')[index - 1] as HTMLElement;
      prevInput.focus();
    }
  }
}
