import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AppStore } from 'src/app/store/app.store';
import { defaultLanguage, languages } from 'src/constants/languages.constants';
import { AccountPageService } from './services/account-page.service';

export const accountPageResolver: ResolveFn<AccountPage> = (route) => {
  const appStore = inject(AppStore);
  const accountPageService = inject(AccountPageService);

  // set language when resolving the account page
  const queryParams = route.queryParams;
  if (queryParams['lang'] != undefined) {
    appStore.language = languages.find(language => language.code === queryParams['lang']) ?? defaultLanguage;
  }

  const language = appStore.$language().code;
  const siteName = appStore.$hostUrl();
  return accountPageService.loadAccountPage(siteName, language);
};
