<div class="dtx-login-modal md:tw-px-8">
    @if ($loading()) {
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-max-w-[30rem] tw-mx-auto">
            <div class="tw-flex tw-justify-between tw-mb-12 tw-w-full tw-mx-auto">
                <p-skeleton height="4rem" borderRadius="16px" class="tw-w-1/5"></p-skeleton>
                <p-skeleton height="4rem" borderRadius="16px" class="tw-w-1/5"></p-skeleton>
            </div>

            <p-skeleton height="2.5rem" class="tw-mb-2 tw-mx-auto tw-w-full"></p-skeleton>
            <p-skeleton height="1.5rem" class="tw-mx-auto tw-w-full"></p-skeleton>
            <p-skeleton height="3rem" class="tw-my-8 tw-mx-auto tw-w-full"></p-skeleton>
            <p-skeleton height="3rem" class="tw-mx-auto tw-mb-4 tw-w-full"></p-skeleton>
        </div>
    } @else {
        <p-steps [model]="items" [readonly]="true" [activeIndex]="$activeIndex()" />
        <div class="tw-mt-12 tw-mb-4 tw-text-center">
            @switch ($step()) {
                @case ('ENTER_EMAIL') {
                    <app-enter-email (succeed)="onEmailSucceed($event)"></app-enter-email>
                }
                @case ('ENTER_OTP') {
                    <app-enter-code (succeed)="onOTPSucceed($event)" [email]="email" [code]="code"></app-enter-code>
                }
            }
        </div>
    }
</div>
