import { Routes } from '@angular/router';
import { AppUrlConstant } from '../constants/app-url.constants';
import { ErrorMessage } from '../constants/error-messages.constants';
import { accountPageResolver } from './features/account/account-page.resolve';
import { accountRouteGuard } from './features/account/account.guard';
import { AccountPageComponent } from './features/account/pages/account-page/account-page.component';
import { PublicComponent } from './public/public.component';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';

export const routes: Routes = [
  {
    path: '404',
    component: ErrorPageComponent,
    data: {
      title: '404',
      shortDescription: ErrorMessage.pageNotFound,
      longDescription: ErrorMessage[404],
    },
  },
  {
    path: AppUrlConstant.UNAUTHORIZED,
    component: ErrorPageComponent,
    data: {
      title: '403',
      shortDescription: ErrorMessage.unauthorized,
      longDescription: ErrorMessage[403],
    },
  },
  {
    path: 'account',
    component: AccountPageComponent,
    canActivate: [accountRouteGuard],
    resolve: { page: accountPageResolver },
    loadChildren: () => import('src/app/features/account/account.route').then(m => m.routes),
  },
  {
    path: '',
    component: PublicComponent,
    loadChildren: () => import('src/app/public/public.route').then(m => m.routes),
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: {
      title: '404',
      shortDescription: ErrorMessage.pageNotFound,
      longDescription: ErrorMessage[404],
    },
  },
];
