@if ($visible()) {
    <p-dialog
        [visible]="this.$visible()"
        [modal]="true"
        [style]="{ width: '50rem' }"
        (visibleChange)="onVisibleChange($event)"
        [draggable]="false"
        [resizable]="false"
        styleClass="dtx-login-modal-dialog"
    >
        <app-login-modal
            [code]="$code"
            [email]="$email"
            [redirectUrl]="$queryParams()?.redirectUrl"
            (succeed)="onVisibleChange(false)"
        ></app-login-modal>
    </p-dialog>
}
