import { CommonModule } from '@angular/common';
import { Component, effect, Signal } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { SEOService } from 'src/app/core/seo/seo.service';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { AppStore } from 'src/app/store/app.store';
import { SideMenuComponent } from '../../components/side-menu/side-menu.component';
import { AccountPageService } from '../../services/account-page.service';
import { AccountPageState } from '../../services/account-page.state';

@Component({
  selector: 'app-account-page',
  standalone: true,
  templateUrl: './account-page.component.html',
  styleUrl: './account-page.component.scss',
  imports: [CommonModule, SideMenuComponent, RouterOutlet, TranslatePipe, HeaderComponent, FooterComponent],
})
export class AccountPageComponent {
  $pageContent!: Signal<AccountPage | null>;
  menuItems: MenuItem[] = [
    {
      Slug: 'groups',
      Text: 'Groups',
    },
    // {
    //   Slug: 'languages',
    //   Text: 'Languages',
    // },
  ];

  constructor(
    private accountPageState: AccountPageState,
    private translateService: TranslateService,
    private accountPageService: AccountPageService,
    private seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    private appStore: AppStore,
  ) {
    this.$pageContent = this.accountPageState.$pageContent;
    this.activatedRoute.data.subscribe(data => {
      const pageContent = data['page'] as AccountPage;
      this.accountPageState.pageContent = pageContent;
      this.updateAppStoreFromPageContent(pageContent);
    });

    effect(
      () => {
        const pageContent = this.$pageContent();
        if (!pageContent) return;
        this.updateSEO(pageContent);
        this.updateAppStoreFromPageContent(pageContent);
      },
      { allowSignalWrites: true },
    );

    // * Reload the content whenever language or hostUrl changes
    effect(async () => {
      const language = this.appStore.$language().code;
      const siteName = this.appStore.$hostUrl();
      this.accountPageState.pageContent = await this.accountPageService.loadAccountPage(siteName, language);
    });
  }

  private updateAppStoreFromPageContent(pageContent: AccountPage) {
    this.appStore.pageStrings = pageContent?.PageStrings;
    this.appStore.appName = pageContent?.AppName;
    this.appStore.systemStrings = pageContent?.SystemStrings as any;
  }

  updateSEO(pageContent: AccountPage | null) {
    if (!pageContent) return;
    this.seoService.updateTitle('Account');
    this.seoService.updateIcon(pageContent?.PageConfiguration?.Icon ?? '');
    this.seoService.updateMetaDescription(pageContent);
    this.seoService.updateMetaImage(pageContent);
    this.seoService.updateMetaCanonical(pageContent);
    this.seoService.updateDtxDynamicStyling(pageContent);
    this.seoService.updateDtxDynamicColours(pageContent);
    this.seoService.updateDtxDynamicScripts(pageContent);
  }
}
