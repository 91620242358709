import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoginPageComponent } from '../features/login/pages/login-page/login-page.component';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
  standalone: true,
  imports: [RouterOutlet, LoginPageComponent],
})
export class PublicComponent {}
