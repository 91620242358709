import { effect, Injectable, signal } from '@angular/core';
import { AppStore } from 'src/app/store/app.store';
import { LoginPageService } from './login-page.service';

@Injectable({
  providedIn: 'root',
})
export class LoginPageState {
  public readonly $loginPageContent = signal<LoginPageContent | null>(null);

  constructor(
    private readonly loginPageService: LoginPageService,
    private readonly appStore: AppStore,
  ) {
    effect(() => {
      const language = this.appStore.$language().code;
      const siteName = this.appStore.$hostUrl();
      this.loginPageService.loginPageContent(siteName, language).then(res => this.$loginPageContent.set(res));
    });
  }
}
