<div class="container-design">
    <div class="not-found">
        <p class="error-code">
            {{ title }}
        </p>
        <p class="shortDescription">
            {{ shortDescription }}
        </p>
        <p class="longDescription">
            {{ longDescription }}
        </p>
        <button (click)="routeToHomePage()" class="back-to-home">Back to home</button>
    </div>
</div>
