import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Output, Signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TranslateService } from 'src/app/shared/services/translate.service';
import { LoginPageState } from '../../services/login-page.state';

@Component({
  selector: 'app-enter-email',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, ButtonModule, InputTextModule, RippleModule, TranslatePipe],
  templateUrl: './enter-email.component.html',
  styleUrl: './enter-email.component.scss',
})
export class EnterEmailComponent {
  form: FormGroup;
  errorMessage: string | undefined;

  @Output()
  succeed = new EventEmitter();

  loading = false;
  $loginPageContent: Signal<LoginPageContent | null>;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private location: Location,
    private loginPageState: LoginPageState,
    private translateService: TranslateService,
  ) {
    this.form = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.$loginPageContent = this.loginPageState.$loginPageContent;
  }

  onSubmit(): void {
    if (this.loading) return;

    if (this.form.valid) {
      const email = this.form.get('email')?.value;

      this.loading = true;
      this.errorMessage = '';
      // Call the API using authService
      this.authService.sendLogon(email).subscribe({
        next: () => {
          this.loading = false;
          this.succeed.emit(email);
        },
        error: error => {
          this.loading = false;
          console.log(JSON.stringify(error));
          this.errorMessage = error.error?.message || this.$loginPageContent()?.ErrorText;
        },
      });
    } else {
      this.errorMessage = `${this.translateService.translate('Invalid')} ${this.translateService.translate('Email')}!`;
    }
  }

  public back() {
    this.location.back();
  }
}
