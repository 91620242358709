<p-toast position="top-right" key="{{ key }}" [baseZIndex]="5000" class="push-notification-confirmation">
    <ng-template let-message pTemplate="message">
        <div class="tw-pt-1 tw-flex tw-items-baseline">
            <i class="fa fa-bell tw-px-2 tw-mr-2 tw-text-slate-500"></i>
            <div>
                <div class="tw-mb-3 tw-text-slate-500 tw-font-semibold">
                    {{ ('EnablePushNotification' | translateSignal: 'Would you like to receive notifications?')() }}
                </div>
                <div class="tw-flex tw-gap-4 tw-justify-end">
                    <p-button
                        [loading]="loading"
                        severity="primary"
                        [text]="true"
                        size="small"
                        label="{{ ('Yes' | translateSignal)() }}"
                        (click)="enablePushNotification()"
                    ></p-button>
                    <p-button
                        severity="secondary"
                        [text]="true"
                        size="small"
                        label="{{ ('No' | translateSignal)() }}"
                        (click)="disablePushNotification()"
                    ></p-button>
                </div>
            </div>
            <i class="fa fa-close tw-absolute tw-top-0 tw-right-1 tw-p-3 tw-text-slate-400" (click)="close()"></i>
        </div>
    </ng-template>
</p-toast>
